<template>
	<div id="customers">
		<main class="container-wide">
			<div class="flex space-between mb-40">
				<div>
					<h1 class="mb-10">Kundar</h1>

					<p class="color-grey">Yvirlit yvir kundar hjá Terminal.fo</p>
				</div>

				<div class="flex align-center">
					<div class="mr-10 relative search-input small">
						<input
							ref="searchInput"
							type="text"
							class="focus:background-light-grey-100"
							v-model="searchString"
							@keydown.esc="searchString = ''"
							placeholder="Leita"
						/>

						<div class="icon-search"></div>
					</div>

					<a href="" class="btn-round mr-10 fas fa-sync" :class="{ 'fa-spin': loading }" @click.prevent="syncCustomers()" title="Synkronisera"></a>

					<router-link class="btn-add" :to="{ name: 'CustomerCreate' }" title="Nýggjan kunda"></router-link>
				</div>
			</div>

			<table v-if="!loading">
				<thead>
					<th>Navn</th>
					<th>Hjánavn</th>
					<th>Bústaður</th>
					<th>Býur</th>
					<th>Email</th>
					<th>Telefon</th>
					<th class="right">&nbsp;</th>
				</thead>

				<tbody>
					<tr v-for="(customer, index) in filteredCustomers" :key="`customer-${index}`">
						<td>
							<a href="#" @click.prevent="showCustomer(customer.id)">{{ customer.name ? customer.name : '-' }}</a>
						</td>
						<td>{{ customer.otherName ? customer.otherName : '-' }}</td>
						<td>{{ customer.address }}</td>
						<td>{{ customer.city }}</td>
						<td>{{ customer.email }}</td>
						<td>{{ customer.phoneNumber }}</td>
						<td class="right">
							<i class="far fa-edit cursor-pointer" @click="editCustomer(customer.id)" title="Rætta kunda"></i>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="flex justify-center mt-80" v-if="loading">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</main>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Customers',

	components: {},

	data() {
		return {
			loading: false,
			searchString: '',
			customers: [],
		};
	},

	computed: {
		filteredCustomers() {
			return this.customers.filter((item) => {
				const fields = ['name', 'otherName', 'address', 'city', 'email', 'phoneNumber', 'vtal'];

				for (const field of fields) {
					if (item[field] && item[field].toLowerCase().indexOf(this.searchString.toLowerCase()) > -1) {
						return true;
					}
				}

				return false;
			});
		},
	},

	mounted() {
		this.loading = true;

		this.$refs.searchInput.focus();

		axios
			.get('/customers')
			.then((response) => {
				this.customers = response.data;
			})
			.catch((error) => {
				console.log('Error fetching customer from E-conomic', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		syncCustomers() {
			this.loading = true;

			axios
				.post('/customers/economic/sync')
				.then((response) => {
					this.customers = response.data;
				})
				.catch((error) => {
					console.log('Error syncing customers', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		showCustomer(customerId) {
			this.$router.push({ name: 'CustomerShow', params: { id: customerId } });
		},

		editCustomer(customerId) {
			this.$router.push({ name: 'CustomerEdit', params: { id: customerId } });
		},
	},
};
</script>
